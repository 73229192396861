import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { Component, BaseComponent, Prop, namespace, Watch } from '@zento-lib/components';
import { FormatCurrency } from '@zento-lib/components/i18n/FormatCurrency';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { Microcart } from 'theme/stores/cart/components/Microcart';
import { decodePhpHtmlString } from '@zento-lib/util/html';
import { USPStore } from 'theme/stores/usp/usp';
import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { FbqConversionApiStore } from 'theme/stores/fbqConversionApi/fbqConversionApi';
import { getFBQConversionData, getFBQEventId } from '@zento-lib/components/AnalyticsServices/helpers';
import { CartSummaryButton } from '@zento/modules/molecule/Cart/SummaryButton/SummaryButton';
import { CartProduct } from '@zento/modules/molecule/Cart/Product/Product';
import { EditModal } from '@zento/modules/molecule/Cart/Edit/Modal';
import { CartCoupon } from '@zento/modules/molecule/Cart/Coupon/Coupon';
import { CrosssellProducts } from '@zento/modules/molecule/Cart/Crosssell/Crosssell';
import { CartHeader } from '@zento/modules/atom/Cart/Header/Header';
import { CartEmpty } from '@zento/modules/atom/Cart/Empty/Empty';
import { InnerHTML } from '@zento/modules/atom/InnerHTML';
import { CartExpand } from '@zento/modules/molecule/Cart/Expand/Expand';
import { CartPayment } from '@zento/modules/atom/Cart/Payment/Payment';
import { Button } from '@zento/modules/atom/Button/Button';
import { FreeShippingMethods } from '@zento/modules/atom/Cart/FreeShippingMethods/FreeShippingMethods';
import { FreeShippingMethodsStore } from 'theme/stores/freeShippingMethods/freeShippingMethods';
import { FreeGiftStore } from 'theme/stores/freeGift/freeGift';

import type { CartTotalItems } from '../types/CartTotalTypes';

import type { ICart } from './Cart.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(AppContextKey);

interface IMicrocartMixin {
  productsInCart: IProductState[];
  totals: CartTotalItems[];
  closeMicrocart(): void;
  removeCoupon(): Promise<boolean>;
  toggleMicrocart(): void;
}

/**
 * Cart
 *
 * Main cart component rendering cart items, coupon, usp and cart totals information
 */
@Component({
  mixins: [Microcart, onEscapePress],
})
export class Cart extends BaseComponent<ICart, IMicrocartMixin> {
  private static T = {
    microcartSidebarTitle: 'microcart_sidebar_title',
    cartEmptyTitle: 'cart_empty_title',
    cartEmptyText: 'cart_empty_text',
    cartStartShoppingText: 'cart_start_shopping_text',
    cartSummaryBtnLabel: 'cart_summary_btn_label',
    cartSummaryCheckoutBtnLabel: 'cart_summary_checkout_btn_label',
    cartCountProducts: 'cart_count_products',
    cartCountProduct: 'cart_count_product',
    cartCloseLabel: 'sidebar_menu_close_button',
    clearCoupon: 'cart_clear_coupon',
    clearAllCartProducts: 'clear_all_cart_products',
    cartCancelActionMsg: 'cancel_action_msg',
    cartNotificationActionMsg: 'notification_action',
    clearCartBtnLabel: 'clear_cart_btn_label',
    expandView: 'expand_view',
    collapseView: 'collapse_view',
    cartBackOrderMessage: 'cart_back_order_message',
  };

  /**
   * Determines if the cart is visible
   */
  @Prop({ type: Boolean, required: true })
  isOpened: boolean;

  /**
   * Determines cart style animations
   */
  @Prop({ type: String, required: false, default: 'right' })
  slideAnimation?: 'right' | 'bottom';

  /**
   * Determines if cart has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: false })
  customStyle?: boolean;

  @appContextStore.Getter('isServer')
  isServer: boolean;

  protected uspStore = new USPStore();
  private storeConfig = new StoreConfig();
  private fbqConversionApiStore = new FbqConversionApiStore();
  private freeShippingStore = new FreeShippingMethodsStore();
  private freeGiftStore = new FreeGiftStore();

  data() {
    return {
      componentLoaded: false,
      addCouponPressed: false,
      expandCart: false,
    };
  }

  @Watch('isOpened')
  onIsOpenedChange() {
    if (this.isOpened && !this.isServer && this.extended.productsInCart.length > 0) {
      this.broadcast('analytics/cart', { products: this.extended.productsInCart });
      this.broadcast('analytics/cart-custom', { products: this.extended.productsInCart });
    }
  }

  /**
   * Fetch cart usp data
   */
  async fetchCartUsp() {
    return this.uspStore.fetchUsp();
  }

  async fetchGiftProductData() {
    return this.freeGiftStore.fetchFreeGift();
  }

  /**
   * Fetch cart usp on server side
   */
  public async serverPrefetch() {
    return await this.uspStore.runInParallel(this.fetchCartUsp(), this.fetchGiftProductData());
  }

  /**
   * Determines backorder product
   */
  get backOrderProduct() {
    return this.extended.productsInCart.find((b) => b.stock?.backorders);
  }

  get cartSummaryTotals() {
    let totals = this.extended.totals;

    if (!this.extended.$config.zento.cart.enableTotalTva) {
      totals = this.extended.totals.filter((segment) => segment.code !== 'tax' && segment.code !== 'grand_total');
    } else {
      totals = this.extended.totals.filter((segment) => segment.code !== 'grand_total');
    }

    if (!this.extended.$config.zento.cart.enableDelivery) {
      totals = this.extended.totals.filter((segment) => segment.code !== 'shipping');
    }

    if (!this.extended.$config.zento.cart.enableTotalTva) {
      return totals;
    } else {
      return totals;
    }
  }

  get cartGrandTotals() {
    return this.extended.totals.filter((segment) => segment.code === 'grand_total');
  }

  closeMicrocartExtend() {
    this.extended.toggleMicrocart();
    this.$store.commit('ui/setSidebar', false);
    this.$store.commit('myAccount/setModalActive', false);
    this.$data.addCouponPressed = false;
    document.body.style.overflow = 'visible';
  }

  clearCoupon() {
    this.extended.removeCoupon();
    this.$data.addCouponPressed = false;
  }

  clearCart() {
    this.$store.dispatch('notification/spawnNotification', {
      type: 'warning',
      message: this.getTranslation({ id: Cart.T.clearAllCartProducts }),
      action1: { label: this.getTranslation({ id: Cart.T.cartCancelActionMsg }), action: 'close' },
      action2: {
        label: this.getTranslation({ id: Cart.T.cartNotificationActionMsg }),
        action: async () => {
          // just clear the items without sync
          await this.$store.dispatch('cart/clear', { disconnect: false });
          await this.$store.dispatch('cart/sync', { forceClientState: true });
        },
      },
      hasNoTimeout: true,
    });
  }

  onEscapePress() {
    this.$store.commit('ui/setMicrocart', false);
    this.$store.commit('ui/setSidebar', false);
    document.body.style.overflow = 'visible';
  }

  callExpandCart() {
    this.$data.expandCart = !this.$data.expandCart;
  }

  private get countProducts() {
    if (this.extended.$config.zento.cart.countQty) {
      let count = 0;
      this.extended.productsInCart.forEach((product) => (count += product.qty));

      return count;
    } else {
      return this.extended.productsInCart.length;
    }
  }

  private goToCheckout() {
    this.$router.push(this.extended.localizedRoute('/checkout'));

    if (!this.extended.$config.zento.analytics.customConfig) {
      this.broadcast('analytics/go-to-checkout', {
        products: this.extended.productsInCart,
        total: this.cartGrandTotals[0].value,
        id: { event_id: this.eventId },
      });

      if (this.storeConfig.storeConfigurations.facebookConversionGeneralActive) {
        this.fbqConversionApiStore.send(
          getFBQConversionData({
            items: this.extended.productsInCart,
            eventName: 'InitiateCheckout',
            eventId: this.eventId,
            sourceUrl: '/checkout',
            content_name: this.extended.productsInCart.map((p) => p.name).join(', '),
            content_category: this.extended.productsInCart
              .map((p) => p.category?.map((cat) => cat.name).join(', '))
              .join(', '),
            user_data: this.$store.state.checkout.shippingDetails,
          }),
        );
      }
    }
  }

  async mounted() {
    this.$nextTick(() => {
      this.$data.componentLoaded = true;
    });

    if (!this.uspStore.uspsCart.length) {
      this.fetchCartUsp();
    }

    if (!Object.keys(this.freeGiftStore.freeGift).length) {
      await this.fetchGiftProductData();
    }

    if (this.freeGiftStore.freeGift.enabled && this.freeGiftStore.freeGift.sku) {
      await this.$store.dispatch('cart/syncFreeGift');
    }
  }

  beforeMount() {
    this.closeMicrocartExtend = this.closeMicrocartExtend.bind(this);
    this.clearCoupon = this.clearCoupon.bind(this);
    this.clearCart = this.clearCart.bind(this);
    this.goToCheckout = this.goToCheckout.bind(this);

    this.$on('cart/checkout-disabled', (resp: { status: number; result: string }) => {
      if (resp.status !== 200) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: this.getTranslation({ id: resp.result }),
        });
      }
    });
  }

  beforeDestroy() {
    this.$off('cart/checkout-disabled');
  }

  private get eventId() {
    return getFBQEventId(
      'InitiateCheckout',
      this.extended.productsInCart.map((p) => p.id),
    );
  }

  render() {
    const productsInCart = this.extended.productsInCart;

    return (
      <transition
        name={this.slideAnimation === 'bottom' ? 'slide-in-bottom' : 'slide-in-right'}
        enterActiveClass={
          this.slideAnimation === 'bottom' ? style.slideInBottomEnterActive : style.slideInRightEnterActive
        }
        leaveActiveClass={
          this.slideAnimation === 'bottom' ? style.slideInBottomLeaveActive : style.slideInRightLeaveActive
        }>
        {this.isOpened ? (
          <div
            class={{ [style.microcart]: true, [style.microcartExpand]: this.$data.expandCart }}
            data-testId='microcart'>
            <div class={style.microcartSidebar}>
              <CartHeader
                products={productsInCart}
                cartTitle={{ id: Cart.T.microcartSidebarTitle }}
                cartCountProducts={{
                  id: Cart.T.cartCountProducts,
                  data: { count_products: this.countProducts },
                }}
                cartCountProduct={{
                  id: Cart.T.cartCountProduct,
                  data: { count_product: this.countProducts },
                }}
                handler={this.closeMicrocartExtend}
                showBtn={true}
                ariaLabel={{ id: Cart.T.cartCloseLabel }}
                clearCart={this.clearCart}
                clearCartBtnLabel={{ id: Cart.T.clearCartBtnLabel }}
                customStyle={this.customStyle}
                key='cart-header'
              />

              <FreeShippingMethods countProducts={this.countProducts} />

              {!productsInCart.length ? (
                <CartEmpty
                  cartEmptyTitle={{ id: Cart.T.cartEmptyTitle }}
                  cartEmptyText={{ id: Cart.T.cartEmptyText }}
                  closeCart={this.closeMicrocartExtend}
                  cartStartShoppingText={{ id: Cart.T.cartStartShoppingText }}
                />
              ) : (
                [
                  <div
                    class={{
                      [style.microcartSidebarWrapper]: true,
                      [style.hasFreeShipping]: this.freeShippingStore.freeShippingMethods.length,
                    }}>
                    <ul class={style.cartProductsList}>
                      {!this.$data.expandCart
                        ? productsInCart.map((product) => {
                            return (
                              <CartProduct
                                product={product}
                                displayMsrp={'cart'}
                                customStyle={this.customStyle}
                                freeGiftProductSku={
                                  this.freeGiftStore.freeGift.enabled ? this.freeGiftStore.freeGift.sku : ''
                                }
                                key={product.sku}
                              />
                            );
                          })
                        : null}
                    </ul>

                    {this.backOrderProduct && this.extended.$config.zento.theme.productPage.backOrderSupplier ? (
                      <div class={style.backOrderMessage}>
                        {this.getTranslation({ id: Cart.T.cartBackOrderMessage })}
                      </div>
                    ) : null}

                    <CartCoupon
                      key='cart-coupon'
                      freeGiftProductSku={this.freeGiftStore.freeGift.enabled ? this.freeGiftStore.freeGift.sku : ''}
                    />

                    {this.uspStore.uspsCart ? (
                      <div class={style.cartUspWrapper} key='usp-block-section'>
                        {this.uspStore.uspsCart.map((item) => {
                          return <InnerHTML contents={decodePhpHtmlString(item.content)} class={style.cartUsp} />;
                        })}
                      </div>
                    ) : null}

                    <CartPayment key='cart-payment' />

                    <CartSummaryButton
                      cartSummaryTotals={this.cartSummaryTotals}
                      clearCoupon={this.clearCoupon}
                      key='cart-summary-button'
                    />

                    {this.extended.$config.zento.cart.crosssellDisplay ? (
                      <CrosssellProducts
                        cartProducts={productsInCart}
                        perPageCustom={[
                          [320, this.extended.$config.zento.cart.mobileCarouselItemsPortrait ?? 1.5],
                          [480, this.extended.$config.zento.cart.mobileCarouselItemsPortrait ?? 1.5],
                          [767, this.extended.$config.zento.cart.tabletCarouselItems ?? 2.5],
                          [1025, this.extended.$config.zento.cart.desktopCarouselItems ?? 2.5],
                        ]}
                        key='crossell-products-colection'
                      />
                    ) : null}

                    <EditModal controls={this.extended.$config.zento.addToButtons.showInput} key='edit-modal' />
                  </div>,
                  <div class={style.shoppingCartButtonsWrapper}>
                    {this.cartGrandTotals.map((segment, index) => {
                      return (
                        <div key={index} class={style.shoppingCartGrandTotal}>
                          {segment.title}
                          <span>
                            <FormatCurrency value={segment.value} />
                          </span>
                        </div>
                      );
                    })}
                    <div onClick={this.closeMicrocartExtend} class={style.shoppingCartButtons}>
                      <Button
                        styleType={this.extended.$config.zento.cart.continueShopping}
                        key='continue-shopping'
                        dataTestId='cartContinue'>
                        {this.getTranslation({ id: Cart.T.cartSummaryBtnLabel })}
                      </Button>
                      <Button
                        handler={this.goToCheckout}
                        styleType='primary'
                        dataTestId='cartGoToCheckout'
                        key='go-to-checkout'>
                        {this.getTranslation({ id: Cart.T.cartSummaryCheckoutBtnLabel })}
                      </Button>
                    </div>
                  </div>,
                  this.extended.$config.zento.cart.showExpandCart ? (
                    this.$data.expandCart ? (
                      [
                        <div onClick={this.callExpandCart} class={style.collapseCartBtn} key='expand-cart-btns'>
                          <span>{this.getTranslation({ id: Cart.T.collapseView })}</span>
                        </div>,
                        <CartExpand
                          cartProducts={productsInCart}
                          displayMsrp={'cart'}
                          customStyle={this.customStyle}
                          key='expand-cart'
                        />,
                      ]
                    ) : (
                      <div onClick={this.callExpandCart} class={style.expandCartBtn} key='expand-cart-btn'>
                        <span>{this.getTranslation({ id: Cart.T.expandView })}</span>
                      </div>
                    )
                  ) : null,
                ]
              )}
            </div>
          </div>
        ) : null}
      </transition>
    );
  }
}
