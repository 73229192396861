import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { Wishlist as WishlistVsf } from 'theme/stores/wishlist/components/Wishlist';

import { WishlistProduct } from '../../molecule/WishlistProduct/WishlistProduct';
import { WishlistHeader } from '../../atom/Wishlist/Header/Header';
import { WishlistEmpty } from '../../atom/Wishlist/Empty/Empty';

import type { IWishlist } from './Wishlist.d';
import style from './style.scss';

interface IWishlistMixin {
  productsInWishlist: IProductState[];
}

/**
 * Wishlist
 *
 * Main wishlist component rendering wishlist items information
 */
@Component({
  mixins: [WishlistVsf, onEscapePress],
})
export class Wishlist extends BaseComponent<IWishlist, IWishlistMixin> {
  private static T = {
    removeAllWishlistItems: 'remove_all_wishlist_items',
    wishlistBtnAgreeLabel: 'notification_action',
    wishlistBtnCancelLabel: 'wishlist_btn_cancel_label',
    wishlistSidebarTitle: 'wishlist_sidebar_title',
    wishlistSidebarEmptyTitle: 'wishlist_sidebar_empty_title',
    wishlistSidebarEmptyText: 'wishlist_empty_text',
    wishlistSidebarCreateAccount: 'wishlist_sidebar_create_account_btn_text',
    wishlistSidebarSignIn: 'wishlist_sidebar_sign_in_btn_text',
    wishlistCountProducts: 'wishlist_count_products',
    wishlistCountProduct: 'wishlist_count_product',
    wishlistCloseBtnLabel: 'sidebar_menu_close_button',
  };

  /**
   * Determines if the wishlist is visible
   */
  @Prop({ type: Boolean, required: true })
  isOpened: boolean;

  /**
   * Determines wishlist style animations
   */
  @Prop({ type: String, required: false, default: 'right' })
  slideAnimation?: 'right' | 'bottom';

  /**
   * Determines if wishlist has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: false })
  customStyle?: boolean;

  beforeMount() {
    this.callRegister = this.callRegister.bind(this);
    this.callLogIn = this.callLogIn.bind(this);
  }

  closeWishlistExtend() {
    this.$store.commit('ui/setWishlist', false);
    this.$store.commit('ui/setSidebar', false);
    document.body.style.overflow = 'visible';
  }

  onEscapePress() {
    this.closeWishlistExtend();
  }

  /**
   * Handler for displaying register form
   */
  callRegister() {
    this.$store.commit('ui/setAuthElem', 'register');
    this.$store.commit('ui/setWishlist', false);
    this.broadcast('modal-show', 'modal-signup');
    this.$store.commit('myAccount/setDrawerActive', true);

    if (this.slideAnimation !== 'bottom') {
      this.$store.commit('ui/setOverlay', true);
    }
  }

  /**
   * Handler for displaying login form
   */
  callLogIn() {
    this.$store.commit('ui/setAuthElem', 'login');
    this.$store.commit('ui/setWishlist', false);
    this.broadcast('modal-show', 'modal-signup');
    this.$store.commit('myAccount/setDrawerActive', true);

    if (this.slideAnimation !== 'bottom') {
      this.$store.commit('ui/setOverlay', true);
    }
  }

  render() {
    return (
      <transition
        name={this.slideAnimation === 'bottom' ? 'slide-in-bottom' : 'slide-in-right'}
        enterActiveClass={
          this.slideAnimation === 'bottom' ? style.slideInBottomEnterActive : style.slideInRightEnterActive
        }
        leaveActiveClass={
          this.slideAnimation === 'bottom' ? style.slideInBottomLeaveActive : style.slideInRightLeaveActive
        }>
        {this.isOpened ? (
          <div class={style.wishlist}>
            <div class={{ [style.wishlistSidebar]: true, [style.sidebarCustom]: this.customStyle }}>
              <WishlistHeader
                products={this.extended.productsInWishlist}
                wishlistSidebarTitle={{ id: Wishlist.T.wishlistSidebarTitle }}
                wishlistCountProducts={{
                  id: Wishlist.T.wishlistCountProducts,
                  data: { countproducts: this.extended.productsInWishlist.length },
                }}
                wishlistCountProduct={{
                  id: Wishlist.T.wishlistCountProduct,
                  data: { countproduct: this.extended.productsInWishlist.length },
                }}
                handler={this.closeWishlistExtend}
                ariaLabel={{ id: Wishlist.T.wishlistCloseBtnLabel }}
                customStyle={this.customStyle}
                hey='wishlist-siderbar-header'
              />
              {this.extended.productsInWishlist.length ? (
                <ul>
                  {this.extended.productsInWishlist.map((wishlistProduct) => {
                    return (
                      <WishlistProduct
                        product={wishlistProduct}
                        customStyle={this.customStyle}
                        key={wishlistProduct.id}
                      />
                    );
                  })}
                </ul>
              ) : (
                <WishlistEmpty
                  wishlistSidebarEmptyTitle={{ id: Wishlist.T.wishlistSidebarEmptyTitle }}
                  wishlistSidebarEmptyText={{ id: Wishlist.T.wishlistSidebarEmptyText }}
                  wishlistSidebarCreateAccount={{ id: Wishlist.T.wishlistSidebarCreateAccount }}
                  wishlistSidebarSignIn={{ id: Wishlist.T.wishlistSidebarSignIn }}
                  callRegister={this.callRegister}
                  callLogIn={this.callLogIn}
                  customStyle={this.customStyle}
                  hey='wishlist-siderbar-empty'
                />
              )}
            </div>
          </div>
        ) : null}
      </transition>
    );
  }
}
