import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { IProductState } from 'theme/@types/vsf/stores/product';

import { CartProduct } from '../../Cart/Product/Product';
import { WishlistProduct } from '../../WishlistProduct/WishlistProduct';
import { Link } from '../../../atom/Link/Link';
import { MainImage } from '../../../atom/MainImage/MainImage';

import { ICartExpand } from './Expand.d';
import style from './style.scss';

/**
 * Expand Cart
 *
 * Renders card additional information like products (extend view), wishlist items, etc.
 */
@Component({})
export class CartExpand extends BaseComponent<ICartExpand, unknown> {
  private static T = {
    wishlistSidebarTitle: 'wishlist_sidebar_title',
    wishlistCountProducts: 'wishlist_count_products',
    myRecentlyViewedTitle: 'my_recently_viewed_title',
  };

  /**
   * Current cart products array
   */
  @Prop({ type: Array, required: true })
  cartProducts: IProductState[];

  /**
   * Determines if cart has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: false })
  customStyle?: boolean;

  /**
   * Get product thumbnails
   */
  get productsThumbnails() {
    return this.extended.$config.zento.images.cartThumbnails;
  }

  /**
   * Determines selected swatch image for main image box
   */
  productThumbnailPath(product: IProductState) {
    let thumbnail = product.image;

    if (
      product.type_id === 'configurable' &&
      'configurable_children' in product &&
      product.configurable_children.length &&
      !product.is_configured &&
      'image' in product.configurable_children[0]
    ) {
      thumbnail = product.configurable_children[0].image;

      if (!thumbnail || thumbnail === 'no_selection') {
        const childWithImg = product.configurable_children.find((f) => f.image && f.image !== 'no_selection');

        if (childWithImg) {
          thumbnail = childWithImg.image;
        } else {
          thumbnail = product.image;
        }
      }
    }

    return {
      src: thumbnail,
      loading: thumbnail,
    };
  }

  render() {
    return (
      <div class={style.expandCartSidebar} key='expand-cart-sidebar'>
        <div class={style.expandCartSidebarWrapper}>
          <ul class={style.expandCartSidebarProducts} key='expand-cart-products-wrapper'>
            {this.cartProducts.map((product) => {
              return <CartProduct product={product} customStyle={this.customStyle} key={product.sku} />;
            })}
          </ul>

          {this.$store.state.wishlist.items.length ? (
            <div class={style.expandCartSidebarWishlistProducts} key='expand-cart-wishlist-products-wrapper'>
              <h5 key='expand-cart-wishlist-title'>
                {this.getTranslation({ id: CartExpand.T.wishlistSidebarTitle })}
                <span>
                  {this.getTranslation({
                    id: CartExpand.T.wishlistCountProducts,
                    data: { countproducts: this.$store.state.wishlist.items.length },
                  })}
                </span>
              </h5>

              <ul key='expand-cart-wishlist-products'>
                {this.$store.state.wishlist.items.map((wishlistProduct: IProductState) => {
                  return (
                    <WishlistProduct
                      product={wishlistProduct}
                      customStyle={this.customStyle}
                      key={wishlistProduct.id}
                    />
                  );
                })}
              </ul>
            </div>
          ) : null}

          {this.$store.state['recently-viewed'].items.length > 0 ? (
            <div class={style.expandCartSidebarRecentProducts} key='expand-cart-recent-products-wrapper'>
              <h5 key='expand-cart-recent-products-title'>
                {this.getTranslation({ id: CartExpand.T.myRecentlyViewedTitle })}
              </h5>

              <ul key='expand-cart-recent-products-wrapper'>
                {this.$store.state['recently-viewed'].items.slice(0, 10).map((item: IProductState) => {
                  return (
                    <li key={item.sku}>
                      <Link to={formatProductLink(item as any, currentStoreView().storeCode)} data-testId='productLink'>
                        <MainImage
                          image={this.productThumbnailPath(item)}
                          alt={item.name}
                          width={this.productsThumbnails.width}
                          height={this.productsThumbnails.height}
                          tabletWidth={this.productsThumbnails.tabletWidth}
                          tabletHeight={this.productsThumbnails.tabletHeight}
                          desktopWidth={this.productsThumbnails.desktopWidth}
                          desktopHeight={this.productsThumbnails.desktopHeight}
                        />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
