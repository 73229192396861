import Product from '@vue-storefront/core/modules/catalog/types/Product';

export const WishlistProduct = {
  name: 'Product',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thumbnail() {
      const thumbnail =
        this.product.image && this.product.image !== 'no_selection'
          ? this.product.image
          : this.product.thumbnail && this.product.thumbnail !== 'no_selection'
          ? this.product.thumbnail
          : '';

      return this.getThumbnail(thumbnail, 150, 150);
    },
  },
  methods: {
    removeFromWishlist(product: Product) {
      return this.$store.state.wishlist ? this.$store.dispatch('wishlist/removeItem', product) : false;
    },
  },
};
