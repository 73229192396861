import VueOfflineMixin from 'vue-offline/mixin';
import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';
import { IMyFiltersStore, KEY as MyFiltersKey } from 'theme/@types/zento/stores/myfilters';
import { IVSFStoreUI, KEY as SearchKey } from 'theme/@types/vsf/stores/ui';
import { SearchPanel } from 'theme/stores/catalog/components/Search';
import { NavigationStore } from 'theme/stores/navigation/navigation';
import { SearchState } from 'theme/stores/search/search';
import { TopNavigationData } from 'theme/stores/navigation/types';
import { I18nMessage } from '@zento-lib/components/Base/types';
import { Component, BaseComponent, Prop, namespace, Watch, VariationBase } from '@zento-lib/components';
import { arrayEquals } from '@zento-lib/util/arrays';

import style from './style.scss';
import type { ISearchItem } from './SearchItem.d';

const appContextStore = namespace<AppContextStore>(AppContextKey);
const myFiltersStore = namespace<IMyFiltersStore>(MyFiltersKey);
const mySearchStore = namespace<IVSFStoreUI>(SearchKey);

interface ISearchPanel {
  seeMore(): void;
  makeSearch(): void;
}

export type IconName = 'search' | 'search-white';

/**
 * Search Item
 *
 * Search Item component allowing to show products and items and set a router link to search/no result page.
 **/
@Component({
  mixins: [SearchPanel, VueOfflineMixin],
})
@VariationBase
export class SearchItem extends BaseComponent<ISearchItem, ISearchPanel> {
  protected static V = {
    search1: () => import('./variations/search1/search1').then((m) => m.Search1),
    search2: () => import('./variations/search2/search2').then((m) => m.Search2),
    search3: () => import('./variations/search3/search3').then((m) => m.Search3),
    search4: () => import('./variations/search4/search4').then((m) => m.Search4),
    search5: () => import('./variations/search5/search5').then((m) => m.Search5),
    search6: () => import('./variations/search6/search6').then((m) => m.Search6),
    search7: () => import('./variations/search7/search7').then((m) => m.Search7),
    search8: () => import('./variations/search8/search8').then((m) => m.Search8),
  };

  /**
   * Data testid.
   */
  @Prop({ type: String, default: undefined })
  testId?: string;

  /**
   * Placeholder for search.
   */
  @Prop({ type: Object, default: undefined })
  searchPlaceholder?: I18nMessage;

  /**
   * Button for search.
   */
  @Prop({ type: Object, default: undefined })
  searchButton?: I18nMessage;

  /**
   * Title Products.
   */
  @Prop({ type: Object, default: undefined })
  searchProducts?: I18nMessage;

  /**
   * Title for see more button.
   */
  @Prop({ type: Object, default: undefined })
  searchSeeMore?: I18nMessage;

  /**
   * Search label.
   */
  @Prop({ type: Object, default: undefined })
  searchLabel?: I18nMessage;

  /**
   * Cancel search label.
   */
  @Prop({ type: Object, default: undefined })
  searchCancel?: I18nMessage;

  /**
   * Icon.
   */
  @Prop({ type: String, default: 'search' })
  icon?: IconName;

  /**
   * Cancel search label.
   */
  @Prop({ type: Object, default: undefined })
  searchClear?: I18nMessage;

  @appContextStore.Getter('isServer')
  isServer: boolean;

  @myFiltersStore.State('drawerFilter')
  protected isDrawerFilter: IMyFiltersStore['state']['drawerFilter'];

  @appContextStore.Getter('isMobile')
  isMobile: boolean;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  private navigationStore = new NavigationStore();
  protected activeSearch = new SearchState();

  @mySearchStore.State('overlay')
  public overlayActive: IVSFStoreUI['state']['overlay'];

  @Watch('isMobile', { immediate: true, deep: true })
  @Watch('products')
  isProducts(newVal: any[], oldVal: any[]) {
    const newIds = Array.isArray(newVal) ? newVal.map((i) => i.id) : [];
    const oldIds = Array.isArray(oldVal) ? oldVal.map((i) => i.id) : [];

    // "arrayEquals" prevents multiple broadcasts of the same data
    if (!this.isServer && this.$data.search.length > 0 && this.$data.search !== '' && !arrayEquals(newIds, oldIds)) {
      this.broadcast('analytics/product-search-track', {
        products: this.$data.products,
        list: 'Search',
        label: 'Search: ' + this.$data.search,
      });
      this.broadcast('analytics/product-search-track-custom', {
        products: this.$data.products,
        list: 'Search',
        label: 'Search: ' + this.$data.search,
      });
    }
  }

  data() {
    return {
      searchVisible: false,
      autofocus: false,
      searchState: this.activeSearch?.searchState,
    };
  }

  private unwatch: Array<() => void> = [];

  /**
   * Get visible products
   */
  get visibleProducts() {
    return this.$data.products || [];
  }

  /**
   * Get categories available once user type values in input search
   */
  get categories() {
    const inputVal = this.$data.search.toLowerCase();
    const items = this.navigationStore.navigationData.children_data || [];

    if (items.length > 0 && inputVal.length > 0) {
      return this.unfoldCategoryTree(items).filter((c) => c.name.includes(inputVal));
    }

    return [];
  }

  private unfoldCategoryTree(categories: TopNavigationData[], acc = []): Array<{ name: string; url: string }> {
    return categories.reduce((ret, c) => {
      if (c.name) {
        ret.push({ name: c.name.toLowerCase(), url: c.url_path });
        this.unfoldCategoryTree(c.children_data || [], acc);
      }

      return ret;
    }, acc);
  }

  /**
   * Remove scroll from body and animate content to search
   */
  onInputFocus() {
    this.$store.commit('ui/setOverlay', this.isDesktop ? this.extended.$config.zento.theme.searchOverlayActive : true);
    this.activeSearch.searchState = true;
    this.$data.searchState = this.activeSearch.searchState;

    if (!this.isDesktop) {
      const container = this.$el.querySelector('form');

      this.$el.classList.add(style.mobileActiveSearch);
      container.scrollIntoView();
    }

    document.body.style.overflow = 'hidden';
  }

  /**
   * Show active search an overlay
   * Once you navigate to search/no-result page, input already has focus
   */
  handleInput() {
    if (this.$data.search.length > 0 && !this.activeSearch.searchState) {
      this.$store.commit(
        'ui/setOverlay',
        this.isDesktop ? this.extended.$config.zento.theme.searchOverlayActive : true,
      );
      this.activeSearch.searchState = true;
      this.$data.searchState = this.activeSearch.searchState;
    }
  }

  /**
   * Remove scroll from body and animate content to search
   */
  onFocus() {
    this.$store.commit('ui/setOverlay', this.isDesktop ? this.extended.$config.zento.theme.searchOverlayActive : true);
    this.activeSearch.searchState = true;
    this.$data.searchState = this.activeSearch.searchState;

    document.body.style.overflow = 'hidden';
  }

  /**
   * Clear search input
   */
  clearSearchInput() {
    this.$data.search = '';

    // Work arround shadow tree not marking parent component of inner Html component as dirty
    const innerInput = this.$el.querySelector('input') as HTMLInputElement;

    if (innerInput) {
      this.$data.error = false;
      innerInput.value = '';
    }
  }

  handleFocusOut() {
    if (this.isDesktop && !this.extended.$config.zento.theme.searchOverlayActive) {
      this.$data.searchVisible = false;
      this.$store.commit('ui/setOverlay', false);
      this.activeSearch.searchState = false;
      this.$data.searchState = this.activeSearch.searchState;
      document.body.style.overflow = 'visible';
    }
  }

  /**
   * Close search/remove class and clear search input
   */
  closeSearch() {
    this.$data.searchVisible = false;
    this.$store.commit('ui/setOverlay', false);
    this.activeSearch.searchState = false;
    this.$data.searchState = this.activeSearch.searchState;
    document.body.style.overflow = 'visible';

    if (!this.isDesktop) {
      this.$el.classList.remove(style.mobileActiveSearch);
      document.body.scrollIntoView();
    }

    this.clearSearchInput();
  }

  /**
   * Got to search/no result page
   */
  goToSearchPage(searchValue) {
    if (this.visibleProducts.length > 0) {
      searchValue = '/search/' + this.$data.search;
    } else if (this.$data.search.length > 0) {
      searchValue = '/no-results/' + this.$data.search;
    } else {
      searchValue = '';
    }

    return this.extended.localizedRoute(searchValue);
  }

  /**
   * On enter press redirect to search page
   */
  onEnterPress(ev: Event) {
    ev.preventDefault();

    if (this.$data.search.length > 0) {
      this.$data.error = false;
      this.$router.push(this.goToSearchPage(''));
      this.clearSearchInput();
      this.closeSearch();
    } else {
      /**
       * On enter if no value is present an error will be show for input
       */
      this.$data.error = true;
    }
  }

  beforeMount() {
    this.unwatch.push(
      this.$watch('search', () => {
        this.extended.makeSearch();
      }),
    );

    this.closeSearch = this.closeSearch.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);

    this.onBroadcast('search-state-closed', this.closeSearch);
  }

  beforeDestroy() {
    this.$store.commit('ui/setOverlay', false);
    this.activeSearch.searchState = false;
    this.$data.searchState = this.activeSearch.searchState;
    this.unwatch.forEach((h) => h());
    this.offBroadcast('search-state-closed');
  }
}
