import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers';
import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { I18nMessage } from '@zento-lib/components/Base/types';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';

import { MainImage } from '../../MainImage/MainImage';
import type { CategoryTypes } from '../../types/CategoryTypes';

import type { IMenuLink } from './Link.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

/**
 * Menu Link
 *
 * Main menu link navigation
 **/
@Component({})
export class MenuLink extends BaseComponent<IMenuLink, unknown> {
  /**
   * Denotes the target route of the link. When clicked, the value of the to prop will be passed to
   * router.push() internally, so the value can be either a string or a location descriptor object.
   */
  @Prop({ type: Object, required: true })
  to: { path: string; message: string; image?: string; emphasis?: string; category_icon?: string } | CategoryTypes;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   */
  @Prop({ type: Function, default: () => undefined })
  handler?: (ev: Event) => void;

  @Prop({ type: Boolean, default: false })
  replace?: boolean;

  @Prop({ type: Boolean, default: false })
  desktopMenuItem?: boolean;

  /**
   * Determine see all link subcategory
   */
  @Prop({ type: Boolean, default: false })
  seeAll?: boolean;

  /**
   * See all label.
   */
  @Prop({ type: Object, default: undefined })
  seeAllLabel?: I18nMessage;

  /**
   * Determine if header menu link displays the item image
   */
  @Prop({ type: Boolean, default: false })
  hasImage?: boolean;

  /**
   * Add same styling for desktop drawer
   */
  @Prop({ type: Boolean, required: false, default: false })
  desktopDrawer?: boolean;

  /**
   * Determines target group
   */
  @Prop({ type: Boolean, required: false, default: false })
  target?: boolean;

  /**
   * Determines target group
   */
  @Prop({ type: String, required: false, default: 'cms_page' })
  type?: string;

  /**
   * Determines level menu
   */
  @Prop({ type: Number })
  level?: number;

  /**
   * Determines level menu and items
   */
  @Prop({ type: Boolean, default: false })
  cropImage?: boolean;

  /**
   * Determines emphasis type
   */
  @Prop({ type: String, required: false, default: '' })
  emphasis?: string;

  @appContextStore.Getter('isDesktop')
  protected isDesktop: boolean;

  get location() {
    if ('message' in this.to) {
      if (this.type === 'external' || this.type === 'blog') {
        return this.to.path;
      }

      return this.extended.localizedRoute(this.to.path);
    }

    if (this.to.kind !== 'group') {
      return formatCategoryLink({ url_path: this.to.url ?? this.to.url_path, slug: this.to.slug });
    }

    return null;
  }

  onClick(ev: MouseEvent) {
    // Execute handler
    this.handler(ev);

    if (!ev.defaultPrevented) {
      ev.preventDefault();

      if (this.location) {
        // Navigate to the expected route using the router if default is not prevented from custom handler
        if (this.replace) {
          this.$router.replace(this.location, this.noOp);
        } else {
          this.$router.push(this.location, this.noOp);
        }
      }
    }
  }

  private noOp() {
    // Do nothing
  }

  beforeMount() {
    this.onClick = this.onClick.bind(this);
  }

  get imageSizes() {
    const config = this.extended.$config.zento.images;

    return this.isDesktop && this.level === 3 ? config.menuLinkLevel3 : config.menuLink;
  }

  render() {
    if (!this.to) {
      return null;
    }

    const baseUrl = this.extended.$config.zento.theme.storeData.storeUrl;
    const image = this.to.category_icon
      ? !this.to.category_icon.startsWith('/media')
        ? '/media' + this.to.category_icon
        : this.to.category_icon
      : this.to.image
      ? !this.to.image.startsWith('/media')
        ? '/media/' + this.to.image
        : this.to.image
      : '';

    return 'message' in this.to ? (
      <a
        href={this.location}
        onClick_capture={this.onClick}
        class={{
          [style.categoryButtonNext]: !!this.handler,
          [style.medium]: this.emphasis === 'medium',
          [style.high]: this.emphasis === 'high',
        }}>
        {image ? (
          <div class={style.menuImgWrapper}>
            <i>
              {/* TODO: temporary added img tag until navigation manager image support cache path and webp format, then MainImage Component can be used */}
              <img
                src={baseUrl + image}
                srcset={baseUrl + image + ' 1x, ' + baseUrl + image + ' 2x'}
                alt={this.to.message}
                loading='lazy'
              />
            </i>
            <span>{this.to.message}</span>
          </div>
        ) : (
          <span>{this.to.message}</span>
        )}
      </a>
    ) : this.target ? (
      <span
        onClick_capture={this.onClick}
        data-promoted={this.to.category_type === 2}
        class={{
          [style.categoryButtonNext]: !!this.handler,
          [style.desktopMenuItem]: this.desktopMenuItem,
          [style.promoted]: this.to.category_type === 2,
          [style.highlighted]: this.to.category_type === 3,
          [style.promotion]: this.to.category_type === 4,
          [style.desktopDrawer]: this.desktopDrawer,
          [style.medium]: this.emphasis === 'medium',
          [style.high]: this.emphasis === 'high',
        }}>
        {this.to.label}
      </span>
    ) : (
      <a
        href={this.location}
        onClick_capture={this.onClick}
        data-promoted={this.to.category_type === 2}
        class={{
          [style.categoryButtonNext]: !!this.handler,
          [style.desktopMenuItem]: this.desktopMenuItem,
          [style.promoted]: this.to.category_type === 2,
          [style.highlighted]: this.to.category_type === 3,
          [style.promotion]: this.to.category_type === 4,
          [style.desktopDrawer]: this.desktopDrawer,
          [style.medium]: this.emphasis === 'medium',
          [style.high]: this.emphasis === 'high',
        }}>
        {this.hasImage ? (
          <div
            class={{
              [style.menuImgWrapper]: true,
              [style.desktopDrawer]: this.desktopDrawer,
              [style.level3]: this.isDesktop && this.level === 3,
            }}
            key={this.to.name + '-menu-link-img'}>
            <MainImage
              image={{ src: image, loading: image }}
              width={this.imageSizes.width}
              height={this.imageSizes.height}
              tabletWidth={this.imageSizes.tabletWidth}
              tabletHeight={this.imageSizes.tabletHeight}
              desktopWidth={this.imageSizes.desktopWidth}
              desktopHeight={this.imageSizes.desktopHeight}
              resize={this.cropImage ? 'xc' : 'xf'}
              folder=''
              alt={this.to.name}
              class={style.menuImgContainer}
            />
            <span>{this.to.name ?? this.to.label}</span>
          </div>
        ) : (
          <span>
            {this.seeAll ? (
              <span>{this.getTranslation(this.seeAllLabel)}</span>
            ) : (
              <span>{this.to.name ?? this.to.label}</span>
            )}
          </span>
        )}
      </a>
    );
  }
}
