import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';
import type { IProductState } from 'theme/@types/vsf/stores/product';

import type { IWishlistHeader } from './Header.d';
import style from './style.scss';

/**
 * Wishlist Header
 *
 * Renders custom content on wishlist header
 **/
@Component({})
export class WishlistHeader extends BaseComponent<IWishlistHeader, unknown> {
  /**
   * Current products found in wishlist
   */
  @Prop({ type: Array, required: true })
  products: IProductState[];

  /**
   * A text representing wishlist title
   */
  @Prop({ type: Object, required: true })
  wishlistSidebarTitle: I18nMessage;

  /**
   * A text representing number of products found in wishlist (if more than one)
   */
  @Prop({ type: Object, required: true })
  wishlistCountProducts: I18nMessage;

  /**
   * A text representing wishlist product count (exactly one)
   */
  @Prop({ type: Object, required: true })
  wishlistCountProduct: I18nMessage;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   */
  @Prop({ type: Function, default: () => undefined })
  handler: (ev: Event) => void;

  /**
   * An attribute used to define a string that labels the current element
   */
  @Prop({ type: Object, default: undefined })
  ariaLabel?: I18nMessage;

  /**
   * Determines if wishlist has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: false })
  customStyle?: boolean;

  render() {
    return (
      <div class={{ [style.wishlistSidebarHeader]: true, [style.sidebarHeaderCustom]: this.customStyle }}>
        <h2 class={style.wishlistTitle}>
          {this.getTranslation(this.wishlistSidebarTitle)}
          {this.products.length > 1 ? (
            <span class={style.wishlistCount}>{this.getTranslation(this.wishlistCountProducts)}</span>
          ) : this.products.length === 1 ? (
            <span class={style.wishlistCount}>{this.getTranslation(this.wishlistCountProduct)}</span>
          ) : null}
        </h2>
        <span
          onClick={this.handler}
          aria-label={this.getTranslation(this.ariaLabel)}
          data-testId='wishlist-close'
          class={style.wishlistClose}
        />
      </div>
    );
  }
}
