import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { routes } from 'theme/router';
import { IMyAccountStore, KEY as MyAccountKey } from 'theme/@types/zento/stores/myaccount';
import { IVSFUser, KEY as UserKey } from 'theme/@types/vsf/stores/user';
import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import { Link } from '../../atom/Link/Link';

import type { IMyAccount } from './MyAccount.d';
import style from './style.scss';

interface IAccountLinks {
  id: number;
  name: string;
  url: string;
}

const uiUser = namespace<IVSFUser>(UserKey);
const myAccountStore = namespace<IMyAccountStore>(MyAccountKey);

/**
 * My Account
 *
 * My Account component allowing to create button with dropdown.
 **/
@Component({
  mixins: [onEscapePress],
})
export class MyAccount extends BaseComponent<IMyAccount, unknown> {
  /**
   * Label name.
   */
  @Prop({ type: Object, default: undefined })
  myAccountLabel?: I18nMessage;

  /**
   * Logout title.
   */
  @Prop({ type: Object, default: undefined })
  myAccountLogout?: I18nMessage;

  /**
   * My account title heading.
   */
  @Prop({ type: Object, default: undefined })
  myAccountTitle?: I18nMessage;

  /**
   * Username client.
   */
  @Prop({ type: Object, default: undefined })
  myAccountUserName?: I18nMessage;

  /**
   * Determines wishlist style animations
   */
  @Prop({ type: String, required: false, default: 'right' })
  slideAnimation?: 'right' | 'bottom';

  @uiUser.State('current')
  public currentUser: IVSFUser['state']['current'];

  @myAccountStore.State('myaccount')
  public isMyAccountOpen: IMyAccountStore['state']['myaccount'];

  private static accountPathPrefix = '/my-account';

  beforeMount() {
    this.logOut = this.logOut.bind(this);

    this.onBroadcast('user-before-logout', () => {
      if (this.isMyAccountOpen) {
        this.toggleMyAccount();
      }
    });
  }

  beforeDestroy() {
    this.offBroadcast('user-before-logout');
  }

  // TODO: Determine if the account links are meant to be configurable from build system level (promote to @Exposed if)
  public get myAccountLinks(): IAccountLinks[] {
    const links = routes
      .filter(
        (route) =>
          route.path.indexOf(MyAccount.accountPathPrefix) > -1 &&
          route.path.indexOf('orderId') === -1 &&
          route.path.indexOf('reviewId') === -1,
      )
      .map((route, index) => ({
        id: index,
        name: route.name,
        url: route.path,
      }));

    if (!this.extended.$config.zento.productTabs.showReviewsTab) {
      return links.filter((route) => route.url.indexOf('reviews') === -1);
    }

    return links;
  }

  get getUserNameInitials() {
    return this.currentUser.firstname.slice(0, 1).toUpperCase() + this.currentUser.lastname.slice(0, 1).toUpperCase();
  }

  get getUserName() {
    return this.currentUser.firstname + ' ' + this.currentUser.lastname;
  }

  toggleMyAccount() {
    this.$store.dispatch('myAccount/toggleMyAccount');
    this.$store.commit('ui/setOverlay', this.isMyAccountOpen);
    document.body.style.overflow = 'visible';
  }

  onEscapePress() {
    this.$store.commit('myAccount/setMyAccount', false);
    this.$store.commit('ui/setOverlay', false);
  }

  logOut() {
    this.broadcast('user-before-logout');
    this.$store.dispatch('user/logout', { silent: false });
    this.$router.push(this.extended.localizedRoute('/', ''));
    this.$store.commit('ui/setOverlay', false);
  }

  render() {
    return (
      <transition
        name={this.slideAnimation === 'bottom' ? 'slide-in-bottom' : 'slide-in-right'}
        enterActiveClass={
          this.slideAnimation === 'bottom' ? style.slideInBottomEnterActive : style.slideInRightEnterActive
        }
        leaveActiveClass={
          this.slideAnimation === 'bottom' ? style.slideInBottomLeaveActive : style.slideInRightLeaveActive
        }>
        {this.currentUser && this.isMyAccountOpen ? (
          <div
            class={{
              [style.myAccount]: true,
              [style.myAccountCheckout]: this.$route.name === 'Checkout',
            }}>
            <div class={style.myAccountSidebar}>
              <div class={style.myAccountSidebarHeader}>
                <h2 class={style.myAccountTitle}>{this.getTranslation(this.myAccountTitle)}</h2>
                <button onClick={() => this.toggleMyAccount()} aria-label='Close' class={style.myAccountClose} />
              </div>
              <div class={style.myAccountSidebarUser}>
                <h2>
                  <span>{this.getUserNameInitials}</span>
                  {this.getTranslation(this.myAccountUserName)}
                  {this.getUserName}
                </h2>
              </div>
              <div class={style.accountSubmenu}>
                <ul>
                  {this.myAccountLinks.map((page) => {
                    return (
                      <li
                        onClick_capture={() => this.toggleMyAccount()}
                        class={{
                          [style.accountLinkActive]: page.url === this.$route.path,
                        }}
                        key={page.id}>
                        <Link to={page.url} styleType='secondary'>
                          {this.getTranslation({ id: page.name })}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <div class={style.logOut}>
                  <a onClick={this.logOut}>
                    <span>{this.getTranslation(this.myAccountLogout)}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </transition>
    );
  }
}
