import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { WishlistProduct as Product } from 'theme/stores/wishlist/components/Product';

import { AddToCart } from '../AddToCart/AddToCart';
import { Link } from '../../atom/Link/Link';
import { MainImage } from '../../atom/MainImage/MainImage';
import { ProductPrice } from '../../atom/Product/Price/Price';
import type { FilterType } from '../types/FilterType';

import type { IWishlistProduct } from './WishlistProduct.d';
import style from './style.scss';

interface IWishlistProductMixin {
  thumbnail: string;
  removeFromWishlist: (product: IProductState) => any;
}

/**
 * Wishlist Product
 *
 * Renders information for each wishlist product (image, title, attributes, etc.)
 */
@Component({
  mixins: [Product],
})
export class WishlistProduct extends BaseComponent<IWishlistProduct, IWishlistProductMixin> {
  private static T = {
    wishlistProductSkuLabel: 'product_sku_label',
    wishlistProductRemove: 'wishlist_product_remove_btn_label',
    wishlistProductEdit: 'wishlist_product_edit_btn_label',
    productPriceInclLabel: 'product_price_incl_label',
    productPriceExclLabel: 'product_price_excl_label',
  };

  /**
   * Current wishlist product
   */
  @Prop({ type: Object, required: true })
  product: IProductState;

  /**
   * Determines if wishlist has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: false })
  customStyle?: boolean;

  beforeMount() {
    this.closeWishlistSidebar = this.closeWishlistSidebar.bind(this);
  }

  get image() {
    return {
      loading: this.extended.thumbnail,
      src: this.extended.thumbnail,
      id: {} as FilterType,
    };
  }

  productLink(product: IProductState | any) {
    return formatProductLink(product, '');
  }

  closeWishlistSidebar() {
    this.$store.commit('ui/setWishlist', false);
    document.body.style.overflow = 'visible';
  }

  get imageSizes() {
    return this.extended.$config.zento.images.cartThumbnails;
  }

  render() {
    return (
      <li class={{ [style.wishlistProduct]: true, [style.wishlistProductCustom]: this.customStyle }}>
        <div class={style.wishlistProductInfo}>
          <div onClick={this.closeWishlistSidebar} class={style.wishlistProductImg}>
            <Link to={this.productLink(this.product)}>
              <MainImage
                image={this.image}
                alt={this.product.name}
                width={this.imageSizes.width}
                height={this.imageSizes.height}
                tabletWidth={this.imageSizes.tabletWidth}
                tabletHeight={this.imageSizes.tabletHeight}
                desktopWidth={this.imageSizes.desktopWidth}
                desktopHeight={this.imageSizes.desktopHeight}
              />
            </Link>
          </div>
          <div onClick={this.closeWishlistSidebar} class={style.wishlistProductName}>
            <Link to={this.productLink(this.product)} styleType='secondary'>
              {this.product.name}
            </Link>
            <span class={style.wishlistProductSku}>
              {this.getTranslation({ id: WishlistProduct.T.wishlistProductSkuLabel })}:
              <span>
                {this.product.cod_furnizor && this.product.cod_furnizor.length
                  ? this.product.cod_furnizor + this.product.sku
                  : this.product.sku}
              </span>
            </span>
            <ProductPrice
              product={this.product}
              productPriceInclLabel={{ id: WishlistProduct.T.productPriceInclLabel }}
              productPriceExclLabel={{ id: WishlistProduct.T.productPriceExclLabel }}
              qtyIncrement={true}
              class={style.wishlistProductPrice}
              key='wishlist-product-price-custom'
            />
            {this.customStyle ? (
              <div class={[style.wishlistProductBtns]} key='wishlist-product-btns-custom'>
                <span
                  onClick={() => this.extended.removeFromWishlist(this.product)}
                  class={style.wishlistProductBtnsRemove}
                  data-testId='wishlistRemove'
                />
                {this.product.type_id === 'configurable' ? (
                  <div onClick={this.closeWishlistSidebar}>
                    <Link
                      to={this.productLink(this.product)}
                      styleType='secondary'
                      class={style.wishlistProductBtnsEdit}
                      data-tooltip={this.getTranslation({ id: WishlistProduct.T.wishlistProductEdit })}
                      data-testId='wishlistItemEdit'>
                      {this.getTranslation({ id: WishlistProduct.T.wishlistProductEdit })}
                    </Link>
                  </div>
                ) : (
                  <AddToCart
                    product={this.product}
                    showInput={false}
                    controls={false}
                    labelVisible={false}
                    wishlistItem={true}
                    productQtyIncrement={false}
                    class={style.wishlistProductBtnsAdd}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
        {!this.customStyle ? (
          <div class={style.wishlistProductBtns} key='wishlist-product-btns'>
            <span
              onClick={() => this.extended.removeFromWishlist(this.product)}
              class={style.wishlistProductBtnsRemove}
              data-testId='wishlistRemove'
              data-tooltip={this.getTranslation({ id: WishlistProduct.T.wishlistProductRemove })}>
              {this.getTranslation({ id: WishlistProduct.T.wishlistProductRemove })}
            </span>
            {this.product.type_id === 'configurable' ? (
              <div onClick={this.closeWishlistSidebar}>
                <Link
                  to={this.productLink(this.product)}
                  styleType='secondary'
                  class={style.wishlistProductBtnsEdit}
                  data-tooltip={this.getTranslation({ id: WishlistProduct.T.wishlistProductEdit })}
                  data-testId='wishlistItemEdit'>
                  {this.getTranslation({ id: WishlistProduct.T.wishlistProductEdit })}
                </Link>
              </div>
            ) : (
              <AddToCart
                product={this.product}
                showInput={false}
                controls={false}
                labelVisible={false}
                wishlistItem={true}
                productQtyIncrement={false}
                class={style.wishlistProductBtnsAdd}
              />
            )}
          </div>
        ) : null}
      </li>
    );
  }
}
