import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import { Button } from '../../Button/Button';

import type { ICartEmpty } from './Empty.d';
import style from './style.scss';

/**
 * Empty Cart
 *
 * Renders custom content when the cart is empty
 **/
@Component({})
export class CartEmpty extends BaseComponent<ICartEmpty, unknown> {
  /**
   * A text representing cart empty title
   */
  @Prop({ type: Object, required: true })
  cartEmptyTitle: I18nMessage;

  /**
   * A text representing cart empty body content
   */
  @Prop({ type: Object, required: true })
  cartEmptyText: I18nMessage;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   * In this case, it will close the cart drawer
   */
  @Prop({ type: Function, default: () => undefined })
  closeCart: (ev: Event) => void;

  /**
   * A text representing cart empty button label
   */
  @Prop({ type: Object, required: true })
  cartStartShoppingText: I18nMessage;

  render() {
    return (
      <div class={style.cartEmpty}>
        <div class={style.cartEmptyWrapper}>
          <i></i>
          <h4>{this.getTranslation(this.cartEmptyTitle)}</h4>
          <p>{this.getTranslation(this.cartEmptyText)}</p>
        </div>
        <div class={style.cartEmptyBtn}>
          <Button styleType='primary' handler={this.closeCart}>
            {this.getTranslation(this.cartStartShoppingText)}
          </Button>
        </div>
      </div>
    );
  }
}
