import config from 'config';
import { Logger } from '@vue-storefront/core/lib/logger';

import { prepareQuickSearchQuery } from '../queries/searchPanel';
export const SearchPanel = {
  name: 'SearchPanel',
  data() {
    return {
      products: [],
      search: '',
      size: 'searchPanelQuerySize' in config.zento.theme ? config.zento.theme.searchPanelQuerySize : 3,
      start: 0,
      emptyResults: false,
      readMore: true,
      total: 0,
    };
  },
  methods: {
    async buildSearchQuery(queryText) {
      const searchQuery = await prepareQuickSearchQuery(queryText);
      return searchQuery;
    },
    async makeSearch() {
      if (this.hasTerm) {
        let search = this.search.trim();

        if (config.zento.theme.elasticsearch.lowercase) {
          search = this.search.toLowerCase().trim();
        }

        if (config.zento.theme.elasticsearch.uppercase) {
          search = this.search.toUpperCase().trim();
        }

        const query = await this.buildSearchQuery(search);
        this.$data.start = 0;

        this.$store
          .dispatch('product/list', {
            query,
            start: this.$data.start,
            size: this.$data.size,
            sort: config.zento.theme.category.searchSortByDefault,
            updateState: false,
            configuration: {},
          })
          .then((resp) => {
            this.$data.products = resp.items;
            this.$data.emptyResults = resp.items.length < 1;
            this.$data.total = resp.total;
          })
          .catch((err) => {
            Logger.error(err, 'components-search')();
          });
      } else {
        this.$data.products = [];
        this.$data.emptyResults = true;
      }
    },
  },
  computed: {
    items() {
      return this.$store.state.search;
    },
    hasTerm() {
      return typeof this.$data.search === 'string' && this.$data.search;
    },
  },
};
