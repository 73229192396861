import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { IVSFUser, KEY as UserKey } from 'theme/@types/vsf/stores/user';

import type { IMenuButton } from './Button.d';
import style from './style.scss';

const userStore = namespace<IVSFUser>(UserKey);

/**
 * Menu Button
 *
 * Next/Prev menu navigation button
 **/
@Component({})
export class MenuButton extends BaseComponent<IMenuButton, unknown> {
  private static T = {
    backLabel: 'menu_btn_back_label',
  };

  /**
   * The id attribute specifies a unique id for an HTML element (the value must be unique within the HTML document).
   */
  @Prop({ type: [Number, String], default: '' })
  id?: string | number;

  /**
   * A value indicating the behavior of the button (menu navigation behavior)
   */
  @Prop({ type: String, default: 'account' })
  type: string;

  /**
   * A string indicating UI visible slot
   */
  @Prop({ type: String, default: '' })
  name?: string;

  label?: string;
  categoryChildren?: number;
  parentGroup?: number | string;

  /**
   * A string which represents button link
   */
  @Prop({ type: String, default: '' })
  url?: string;

  @userStore.State('current')
  protected currentUser: IVSFUser['state']['current'];

  beforeMount() {
    this.goToAccount = this.goToAccount.bind(this);
  }

  goToAccount() {
    if (!this.currentUser) {
      this.$store.commit('myAccount/setDrawerActive', true);
      this.$store.commit('ui/setOverlay', true);
      this.$store.commit('ui/setAuthElem', 'login');
      this.broadcast('modal-show', 'modal-signup');
      localStorage.setItem('redirect', this.url);
    } else {
      this.$router.push(this.url);
    }
  }

  /**
   * Handler for back button navigation
   */
  back(ev: Event) {
    ev.preventDefault();

    this.broadcast('menu/selection', null);
  }

  render() {
    return this.type === 'account' ? (
      <button
        onClick={this.goToAccount}
        aria-label={this.getTranslation({ id: this.name })}
        class={style.categoryButton}>
        {this.name}
      </button>
    ) : this.type === 'back' ? (
      <button
        onClick={this.back}
        aria-label={this.getTranslation({ id: MenuButton.T.backLabel })}
        class={style.categoryButtonBack}>
        {this.name}
      </button>
    ) : null;
  }
}
