import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { Component, BaseComponent, namespace, Prop, VariationBase, Watch } from '@zento-lib/components';
import { IVSFStoreUI, KEY as UIKey } from 'theme/@types/vsf/stores/ui';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';
import { TopNavigationData } from 'theme/stores/navigation/types';

import { MenuLink } from '../../atom/Menu/Link/Link';
import { MenuButton } from '../../atom/Menu/Button/Button';
import type { CategoryTypes } from '../../atom/types/CategoryTypes';

import type { IMenu } from './Menu.d';
import style from './style.scss';

const uiStore = namespace<IVSFStoreUI>(UIKey);
const appContextStore = namespace<AppContextStore>(appContextKey);

export type IconName = 'hamburger' | 'hamburger-white';

/**
 * Main Menu
 **/
@Component({
  mixins: [onEscapePress],
})
@VariationBase
export class Menu extends BaseComponent<IMenu, unknown> {
  protected static V = {
    menu1: () => import('./variations/menu1/menu1').then((m) => m.Menu1),
    menu2: () => import('./variations/menu2/menu2').then((m) => m.Menu2),
    menu3: () => import('./variations/menu3/menu3').then((m) => m.Menu3),
    menu4: () => import('./variations/menu4/menu4').then((m) => m.Menu4),
    menu5: () => import('./variations/menu5/menu5').then((m) => m.Menu5),
    menu6: () => import('./variations/menu6/menu6').then((m) => m.Menu6),
    menu7: () => import('./variations/menu7/menu7').then((m) => m.Menu7),
    menu8: () => import('./variations/menu8/menu8').then((m) => m.Menu8),
    menu9: () => import('./variations/menu9/menu9').then((m) => m.Menu9),
    menu10: () => import('./variations/menu10/menu10').then((m) => m.Menu10),
    menu11: () => import('./variations/menu11/menu11').then((m) => m.Menu11),
  };

  protected static T = {
    close: 'sidebar_menu_close_button',
    closeIcon: 'hamburger_close_icon',
    backMainMenuBtn: 'menu_back_to_main_categories',
    backMenu: 'menu_back_to',
    menuShopAllLabel: 'main_menu_shop_all_label',
    labelHamburger: 'menu_label',
  };

  /**
   * Menu data tree
   */
  @Prop({ type: Object, required: true })
  tree: TopNavigationData;

  /**
   * Determines if main menu is visible or not
   */
  @Prop({ type: Boolean, required: true })
  isOpened: boolean;

  @Prop({ type: Number, default: 0 })
  depth?: number;

  /**
   * Add same styling for desktop drawer
   */
  @Prop({ type: Boolean, required: false, default: false })
  desktopDrawer?: boolean;

  /**
   * Icon.
   */
  @Prop({ type: String, default: 'hamburger' })
  icon?: IconName;

  @uiStore.State('submenu')
  protected submenu: IVSFStoreUI['state']['submenu'];

  @appContextStore.Getter('isMobile')
  protected isMobile: boolean;

  @appContextStore.Getter('isDesktop')
  protected isDesktop: boolean;

  @appContextStore.Getter('isServer')
  protected isServer: boolean;

  private static DYNAMIC_MENU_VISIBILITY = 'dynamic-menu-visibility';
  private static INVISIBLE_MENU_ITEM = 'display: none;';
  private static VISIBLE_MENU_ITEM = 'display: block;';
  private static VISIBLE_DESKTOP_DEPTHS = [0, 1, 2];
  private dynamicVisibility!: HTMLStyleElement;
  private lookupMap: Record<string, TopNavigationData>;
  private isVariationRoot = false;

  @Watch('isMobile', { deep: true })
  isMobileDetected() {
    if (this.isVariationRoot) {
      this.updateVisibility();
    }
  }

  @Watch('submenu', { deep: true })
  isSubmenuSelected() {
    if (this.isVariationRoot) {
      this.updateVisibility();
    }
  }

  @Watch('tree.children_data', { deep: true })
  onTreeItemsChanged() {
    if (this.isVariationRoot) {
      this.lookupMap = null;
    }
  }

  created() {
    this.isVariationRoot = BaseComponent.instanceOf(this, Menu);

    if (this.isVariationRoot) {
      // Attach the selection handler in order to allow tracking menu navigations (only at root level)
      this.onBroadcast('menu/selection', (selectedCatId: number) => {
        if (selectedCatId !== null) {
          // Update the store depth keeper
          const submenuDepth = this.submenu ? this.submenu.path.length + 1 : 0;

          this.$store.commit('ui/setSubmenu', {
            id: selectedCatId,
            depth: submenuDepth,
          });
        } else {
          // Went back
          const submenuDepth = this.submenu ? this.submenu.path.length - 1 : 0;

          this.$store.commit('ui/setSubmenu', {
            depth: submenuDepth,
          });
        }

        // TODO: Consider advertising the menu selection for marketing purposes
      });
    }
  }

  beforeMount() {
    this.openSidebarMenu = this.openSidebarMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.viewAll = this.viewAll.bind(this);

    if (!this.isServer && this.isVariationRoot) {
      if (!document.getElementById(Menu.DYNAMIC_MENU_VISIBILITY)) {
        const s = document.createElement('style');
        s.id = Menu.DYNAMIC_MENU_VISIBILITY;
        this.dynamicVisibility = document.head.appendChild(s);
      } else {
        this.dynamicVisibility = document.getElementById(Menu.DYNAMIC_MENU_VISIBILITY) as HTMLStyleElement;
      }

      this.updateVisibility();
    }
  }

  beforeDestroy() {
    if (this.isVariationRoot) {
      this.offBroadcast('menu/selection');
    }
  }

  onEscapePress() {
    if (this.isVariationRoot) {
      this.closeMenu();
    }
  }

  /**
   * Generate the list items for main menu
   */
  protected get items() {
    return [
      // Add back button
      ...(this.selectedCatId
        ? [
            this.$createElement(MenuButton, {
              props: {
                type: 'back',
                name: this.parentCategory
                  ? `${this.getTranslation({ id: Menu.T.backMenu })} ${
                      this.parentCategory.name || this.parentCategory.label
                    }`
                  : this.getTranslation({ id: Menu.T.backMainMenuBtn }),
              },
              key: 'back-menu-btn',
            }),
          ]
        : []),

      // Add view all link
      ...(this.selectedCatId
        ? [
            this.$createElement(
              'li',
              {
                class: this.extended.$config.zento.theme.menu.showAllBtnText
                  ? this.desktopDrawer
                    ? style.viewAllAfter
                    : style.viewAll
                  : style.viewAllMinimal,
              },
              [
                this.$createElement(MenuLink, {
                  props: {
                    to: this.getSubMenuItem(this.selectedCatId),
                    handler: this.viewAll,
                    target: this.getSubMenuItem(this.selectedCatId).kind === 'group',
                  },
                  attrs: {
                    'data-testId': 'viewAllLink',
                    'data-label': this.getTranslation({ id: Menu.T.menuShopAllLabel }),
                    'data-image': this.extended.$config.zento.theme.menu.showAllBtnImg,
                  },
                  key: 'view-all-menu-btn',
                }),
              ],
            ),
          ]
        : []),

      // Add categories
      ...this.categoryChildrenData,
    ];
  }

  /**
   * Return current selected category id
   */
  protected get selectedCatId() {
    return this.submenu ? this.submenu.path[this.submenu.path.length - 1] || null : null;
  }

  /**
   * Return parent category of the current visible submenu
   */
  protected get parentCategory(): CategoryTypes | null {
    const parentOfSelectedCat = this.submenu ? this.submenu.path[this.submenu.path.length - 2] || null : null;

    return this.getSubMenuItem(parentOfSelectedCat);
  }

  /**
   * Generate the list items for main menu
   */
  protected get categoryChildrenData() {
    return this.tree.children_data ?? [];
  }

  /**
   * Get category parent name
   */
  protected get categoryParentName() {
    return this.tree.name ?? '';
  }

  /**
   * Stop the bubbling of an event to prevent any parent event handlers from being executed
   */
  protected stopPropagation(e: Event) {
    e.stopPropagation();
  }

  /**
   * Open categories sidebar
   */
  protected openSidebarMenu() {
    this.$store.commit('ui/setSidebar', !this.isOpened);
    this.$store.commit('ui/setOverlay', !this.isOpened);
  }

  /**
   * Close main menu sidebar
   */
  protected closeMenu() {
    this.$store.commit('ui/setSidebar', false);
    this.$store.commit('ui/setOverlay', false);

    // Reset menu to first level
    this.submenu.path = [];

    if (!this.isDesktop || this.desktopDrawer) {
      document.body.style.overflow = 'visible';
    }
  }

  private updateVisibility() {
    const from = '#main-menu-container';
    const depths =
      this.isDesktop && !this.desktopDrawer
        ? Menu.VISIBLE_DESKTOP_DEPTHS
        : this.selectedCatId
        ? [this.submenu.path.length]
        : [0];

    this.dynamicVisibility.innerText = [
      `${from} [data-depth] > a {${Menu.INVISIBLE_MENU_ITEM}}`,
      `${from} [data-depth] > span {${Menu.INVISIBLE_MENU_ITEM}}`,
      `${from} [data-depth] > button {${Menu.INVISIBLE_MENU_ITEM}}`,
      ...depths.map((d) => {
        if (d > 0 && (this.desktopDrawer || !this.isDesktop)) {
          return `${from} [data-depth='${d}'][data-parentId='${this.selectedCatId}'] > a {${Menu.VISIBLE_MENU_ITEM}}
            ${from} [data-depth='${d}'][data-parentId='${this.selectedCatId}'] > button {${Menu.VISIBLE_MENU_ITEM}}`;
        } else {
          return `${from} [data-depth='${d}'] > a {${Menu.VISIBLE_MENU_ITEM}}
            ${from} [data-depth='${d}'] > span {${Menu.VISIBLE_MENU_ITEM}}
            ${from} [data-depth='${d}'] > button {${Menu.VISIBLE_MENU_ITEM}}`;
        }
      }),
    ].join('\n');
  }

  /**
   * Parent category handler
   */
  private viewAll() {
    this.$store.commit('ui/setSidebar', false);
    this.$store.commit('ui/setOverlay', false);

    // Reset menu to first level
    this.submenu.path = [];

    if (!this.isDesktop || this.desktopDrawer) {
      document.body.style.overflow = 'visible';
    }
  }

  private unfoldCategoryTree(categories: any[], acc = {}) {
    return categories.reduce((ret, c) => {
      return {
        ...ret,
        [c.id]: c,
        ...this.unfoldCategoryTree(c.children_data || [], acc),
      };
    }, acc);
  }

  private getSubMenuItem(id: number | string) {
    if (!this.lookupMap) {
      this.lookupMap = this.unfoldCategoryTree(this.categoryChildrenData);
    }

    return this.lookupMap[id];
  }
}
