import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';
import { IVSFUser, KEY as UserKey } from 'theme/@types/vsf/stores/user';

import { Button } from '../../Button/Button';

import type { IWishlistEmpty } from './Empty.d';
import style from './style.scss';

const uiUser = namespace<IVSFUser>(UserKey);

/**
 * Empty Wishlist
 *
 * Renders custom content when the wishlist is empty
 **/
@Component({})
export class WishlistEmpty extends BaseComponent<IWishlistEmpty, unknown> {
  /**
   * A text representing wishlist empty title
   */
  @Prop({ type: Object, required: true })
  wishlistSidebarEmptyTitle: I18nMessage;

  /**
   * A text representing cart empty body content
   */
  @Prop({ type: Object, required: true })
  wishlistSidebarEmptyText: I18nMessage;

  /**
   * A text representing wishlist empty register button label
   */
  @Prop({ type: Object, required: true })
  wishlistSidebarCreateAccount: I18nMessage;

  /**
   * A text representing wishlist empty login button label
   */
  @Prop({ type: Object, required: true })
  wishlistSidebarSignIn: I18nMessage;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   * In this case, it will close the wishlist drawer and open register form modal
   */
  @Prop({ type: Function, default: () => undefined })
  callRegister: (ev: Event) => void;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   * In this case, it will close the wishlist drawer and open login form modal
   */
  @Prop({ type: Function, default: () => undefined })
  callLogIn: (ev: Event) => void;

  /**
   * Determines if wishlist has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: false })
  customStyle?: boolean;

  @uiUser.Getter('isLoggedIn')
  protected isLoggedIn: boolean;

  render() {
    return (
      <div class={{ [style.wishlistEmpty]: true, [style.wishlistEmptyCustom]: this.customStyle }}>
        <div class={style.wishlistEmptyWrapper}>
          <i></i>
          <h4>{this.getTranslation(this.wishlistSidebarEmptyTitle)}</h4>
          <p>{this.getTranslation(this.wishlistSidebarEmptyText)}</p>
        </div>
        {!this.isLoggedIn ? (
          <div class={style.wishlistEmptyBtn}>
            <Button
              styleType={this.extended.$config.zento.wishlist.buttonStyle}
              handler={this.callRegister}
              class={style.wishlistSignUp}
              key='wishlist-register-btn'
              dataTestId='wishlistRegister'>
              {this.getTranslation(this.wishlistSidebarCreateAccount)}
            </Button>
            <Button
              styleType='primary'
              handler={this.callLogIn}
              class={style.wishlistSignIn}
              key='wishlist-log-in-btn'
              dataTestId='wishlistLogIn'>
              {this.getTranslation(this.wishlistSidebarSignIn)}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}
